.section-makkuse-media {
  .header {
    text-align: center;
    margin-bottom: 35px;
  }
  .slick-initialized {
    margin: 0 70px;
    margin-bottom: 135px;
    .slick-prev {
      //   left: 5px;
      top: 35%;
      // &::before {
      //   content: url("../../images/prev.png");
      // }
    }
    .slick-next {
      //   right: 5px;
      top: 35%;
      // &::before {
      //   content: url("../../images/next.png");
      // }
    }
    .slick-dots {
      bottom: -60px;
      li.slick-active {
        button {
          &::before {
            color: $primary-color;
            opacity: 1;
          }
        }
      }
      li {
        button {
          &::before {
            color: white;
            opacity: 1;
            font-size: 12px;
          }
        }
      }
    }
  }
  .card {
    // margin: 0 27px;
    .image-container {
      max-width: 400px;
      padding-left: 5px;
      padding-right: 5px;
      border-radius: 0;
      img {
        aspect-ratio: 390/260;
      }
    }
    .text-container {
      text-align: center;
      max-width: 100%;
      .sub-title {
        transition: all 0.3s ease-in-out;
        cursor: pointer;
        &:hover {
          color: $primary-color;
        }
      }
    }
  }
}
