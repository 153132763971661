// -----------------------------------------------------------------------------
// This file contains all styles related to the header of the site/application.
// -----------------------------------------------------------------------------
header {
  // position: fixed;
  // top: 0;
  // width: 100%;
  position: relative;
  height: auto;
  padding-bottom: 6px;
  background-color: $secondary-color;
  transition: all 0.3s ease-in-out;
  z-index: 5;
  @media (max-width: 991px) {
    padding-bottom: initial;
  }
  .header-logo-image {
    position: relative;
    height: 100%;
    .logo-container {
      position: absolute;
      width: 150px;
      height: 123px;
      top: 40%;
      @media (max-width: 991px) {
        width: 90px;
        position: relative;
        z-index: 23;
        top: 15%;
      }
    }
  }
  .nav {
    display: flex;
    padding-top: 53px;
    justify-content: end;
    z-index: 222;
    @media (max-width: 991px) {
      justify-content: center;
      align-items: center;
      position: absolute;
      left: -125%;
      top: 123px;
      height: calc(100vh - 123px);
      width: 100%;
      z-index: 0;
      transition: all 0.5s ease;
      &.show {
        left: 0%;
        z-index: 22;
        background-color: $secondary-color;
        .nav-list {
          ul {
            display: flex;
            flex-direction: column;

            li {
              a {
                color: white;
                &:hover {
                  color: $primary-color !important;
                }
              }
            }
            .dropdown {
              a {
                display: inline;
              }
              .accordion {
                .accordion-item {
                  background-color: $secondary-color;
                }
                .accordion-button {
                  background-color: $secondary-color;
                  &:not(.collapsed) {
                    background-color: $secondary-color;
                  }
                  &:after {
                    background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23ffffff'><path fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/></svg>") !important;
                  }
                }
              }
              // .expand-image{
              //   display: inline-block;
              //   width: 17px;
              //   margin-top: 4px;
              //   transform: rotate(180deg);
              //   transition: all 0.3s ease-in-out;
              //   cursor: pointer;
              // }
              // .expand-image.expand{
              //   transform: rotate(0deg);
              // }
              /*  &:hover{
                .dropdown-content{
                  display: none;
                }
              }  */
              .dropdown-content {
                position: static;
                height: auto;
                // padding-left: 25px;
                padding: 0;
                display: flex;
                background-color: inherit;
                margin-top: 8px;
                margin-left: 37px;
                li {
                  padding-left: 16px;
                  margin-right: 0;
                }
              }
            }
          }
        }
      }
    }
    .nav-link {
      padding-bottom: 12px;
      transition: all 0.3s ease-in-out;
    }
    .nav-list {
      ul {
        padding: 0px;
        margin: 0px;
        display: flex;
        list-style: none;
        @media (max-width: 991px) {
          flex-direction: column;
        }

        li {
          position: relative;
          a {
            text-decoration: none;
            color: white;
            position: relative;
            &::before {
              content: "";
              width: 0;
              height: 3px;
              background-color: $primary-color;
              position: absolute;
              bottom: -6px;
              left: 0px;
              transition: all 0.3s ease-in-out;
            }
            &:hover {
              color: $primary-color !important;
              &::before {
                width: 100%;
              }
            }
          }
          .active {
            color: $primary-color !important;
            &::before {
              content: "";
              width: 100%;
              height: 3px;
              background-color: $primary-color;
              position: absolute;
              bottom: -6px;
              left: 0px;
              @media (max-width: 991px) {
                display: none;
              }
            }
          }
          &:first-child {
            margin-right: 29px;
          }
          &:nth-child(2) {
            margin-right: 23px;
          }
          &:nth-child(3) {
            margin-right: 20px;
            @media (max-width: 991px) {
              margin-right: 0;
            }
          }
          &:nth-child(4) {
            margin-right: 8px;
          }
          &:nth-child(5) {
            margin-right: 8px;
          }
        }
        .dropdown {
          position: relative;
          transition: all 0.3s ease-in-out;
          // .expand-image{
          //   display: none;
          // }
          .dropdown-content {
            position: absolute;
            // display: none;
            justify-content: center;
            flex-direction: column;
            background-color: $secondary-color;
            padding: 15px 16px 10px;
            top: 35px;
            gap: 8px;
            left: -5px;
            width: 137%;
            // display: flex;
            transition: all 0.3s ease-in-out;
          }
          // &:hover {
          //   .dropdown-content {
          //     display: flex;
          //     transition: all 1s ease;
          //   }
          // }
        }
      }
    }
  }

  .accordion {
    h2 {
      font-family: "Product Sans", sans-serif;
    }
    .accordion-item {
      border: none;
      background-color: $secondary-color;
    }
    .accordion-button {
      padding: 0;
      background-color: $secondary-color;
      &:after {
        background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='white'><path fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/></svg>") !important;
        margin-left: initial;
      }
      &:focus {
        z-index: 3;
        border-color: none;
        outline: 0;
        box-shadow: none;
      }
      &:not(.collapsed) {
        color: white;
        // background-color: white;
        box-shadow: none;
      }
    }
    .accordion-body {
      padding: 0;
    }
  }
  .menu-toggle {
    width: 40px;
    height: 35px;
    flex-direction: column;
    justify-content: space-between;
    display: none;
    position: absolute;
    top: 50%;
    right: 5%;
    transform: translate(0, -50%);
    cursor: pointer;
    @media (max-width: 991px) {
      display: flex;
    }
    // @media (max-width: 575px) {
    //   width: 33px;
    // }
    span {
      content: "";
      background-color: $primary-color;
      height: 3px;
      display: block;
      transition: all 0.3s ease-in-out;
    }
  }
}

header.scroll-down {
  position: fixed;
  top: -100%;
  width: 100%;
  height: 0;
  transform: translateY(-200%);
  .header-logo-image {
    .logo-container {
      top: 0;
    }
  }
}
header.scroll-up {
  position: fixed;
  width: 100%;
  height: auto;
  z-index: 21;
  transform: translateY(0%);
  box-shadow: 0 8px 15px rgba(0, 0, 0, 0.4);
  .header-logo-image {
    .logo-container {
      width: 85px;
      @media (max-width: 576px) {
        top: 19px;
      }
    }
  }
  .nav {
    padding-top: 25px;
  }
}
.no-header {
  display: none !important;
  @media (max-width: 767px) {
    display: initial;
  }
}
