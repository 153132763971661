.section-form {
  margin-bottom: 166px;
  @media (max-width: 776px) {
    margin-bottom: 0px;
  }
  .container {
    background-color: $black-shade-1;
    padding-top: 84px;
    padding-bottom: 64px;
    padding-right: 193px;
    padding-left: 196px;
    @media (max-width: 991px) {
      padding-left: 150px;
      padding-right: 150px;
    }
    @media (max-width: 776px) {
      padding-left: 100px;
      padding-right: 100px;
    }
    @media (max-width: 644px) {
      padding-left: 12px;
      padding-right: 12px;
    }
  }
  .header-box {
    text-align: center;
    // &.underline{
    //     &:after{
    //         margin-top: 30px;
    //     }
    // }
  }
  .form-container {
    margin-top: 49px;
    margin-bottom: 61px;
    input {
      width: 100%;
    }
    textarea {
      width: 100%;
      height: 130px;
    }
    .form-row {
      display: flex;
      justify-content: space-between;
      @media (max-width: 991px) {
        flex-wrap: wrap;
      }
      div {
        width: 47.73%;
        @media (max-width: 991px) {
          width: 100%;
        }
      }
    }
    .form-submit {
      text-align: center;
      margin-top: 61px;
      input {
        &.btn-main {
          width: 135px;
        }
      }
    }
  }
}
