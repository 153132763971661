.page-product-details {
  .section-product-information {
    padding-top: 0;
    padding-bottom: 100px;
    @media (max-width: 767px) {
      padding-bottom: 75px;
    }
    .card {
      margin-bottom: 0;
      img {
        @media (max-width: 767px) {
          aspect-ratio: 1;
        }
      }
    }
  }
}
.container.product {
  // display: flex;
  // gap: 45px;
  // @media (max-width: 767px) {
  //   flex-direction: column;
  //   gap: 0;
  // }
  max-width: 939px;
}
