.page-contact {
  .container {
    @media (min-width: 1200px) {
      max-width: 1096px;
    }
  }
  .section-contact-text {
    padding-top: 110px;
    padding-bottom: 103px;
    @media (max-width: 767px) {
      padding-top: 50px;
      padding-bottom: 50px;
    }
    .header-box {
      text-align: center;
      &.underline {
        &:after {
          // margin-left: 0;
          // margin-top: 25px;
          margin-bottom: 28px;
          @media (max-width: 767px) {
            margin-left: auto;
          }
        }
      }
    }
    .wrapper + .wrapper {
      margin-top: 40px;
      @media (max-width: 767px) {
        margin-top: 20px;
      }
    }
    .contact-wrapper {
      display: flex;
      gap: 10px;
      flex-wrap: wrap;
      justify-content: center;
    }
    .map-container {
      text-align: center;
      @media (max-width: 767px) {
        margin-top: 50px;
      }
    }
    .contact-container {
      display: flex;
      align-items: center;
      gap: 8px;
      margin-bottom: 15px;
      @media (max-width: 767px) {
        justify-content: center;
      }
      .text-container {
        p {
          color: $primary-color;
          font-size: 15px;
          line-height: 20px;
        }
      }
    }
    .dark-header {
      text-align: center;
      // @media (max-width: 767px) {
      //   margin-top: 50px;
      // }

      &.underline {
        &::after {
          // margin-top: 17px;
          margin-bottom: 23px;
        }
      }
    }
    .button-container {
      display: flex;
      justify-content: center;
      gap: 20px;
      @media (max-width: 500px) {
        flex-direction: column;
        align-items: center;
      }
      .btn-main {
        @media (max-width: 1005px) {
          padding-left: 31px;
          padding-right: 31px;
        }
      }
    }
  }
}
