// -----------------------------------------------------------------------------
// This file contains all application-wide Sass variables.
// -----------------------------------------------------------------------------

/// Regular font family
/// @type List
$text-font-stack: "Open Sans", "Helvetica Neue Light", "Helvetica Neue",
  "Helvetica", "Arial", sans-serif !default;

/// Code (monospace) font family
/// @type List
$code-font-stack: "Courier New", "DejaVu Sans Mono", "Bitstream Vera Sans Mono",
  "Monaco", monospace !default;

/// Copy text color
/// @type Color
$text-color: #222222 !default;

/// Main brand color
/// @type Color
$brand-color: rgb(229, 0, 80) !default;

/// Light grey
/// @type Color
$light-grey: rgb(237, 237, 237) !default;

/// Medium grey
/// @type Color
$mid-grey: rgb(153, 153, 153) !default;

/// Dark grey
/// @type Color
$dark-grey: rgb(68, 68, 68) !default;

/// Container's maximum width
/// @type Length
$max-width: 1180px !default;

/// Breakpoints map
/// @prop {String} keys - Keys are identifiers mapped to a given length
/// @prop {Map} values - Values are actual breakpoints expressed in pixels
$breakpoints: (
  "small": 320px,
  "medium": 768px,
  "large": 1024px,
) !default;

$primary-color: #874f47;
$secondary-color: #070606;
$tertiary-color: #710104;
$text-color: #262626;
$banner-bg-color: #e2e2e2;
$bg-color: #e3e3e3;
$white: #ffffff;
$background-color-section: #e3e3e3;
$input-border: #c9c9c9;
$black-shade-1: #121111;
$grey-shade-1: #707070;
$grey-shade-2: #303030;
$grey-shade-3: #434242;
$white-shade-1: #fff8f3;
