.section-product-information {
  margin-top: 95px;
  @media (max-width: 991px) {
    margin-top: 75px;
  }
  .text-container {
    text-align: center;
    margin-bottom: 45px;
    .header-box {
      max-width: 414px;
      margin-left: auto;
      margin-right: auto;
      margin-bottom: 52px;
      @media (max-width: 767px) {
        max-width: 100%;
      }
    }
    .para {
      max-width: 663px;
      margin-left: auto;
      margin-right: auto;
      text-align: center;
    }
  }
  .card {
    margin-bottom: 45px;
    @media (max-width: 767px) {
      margin-bottom: 0;
    }

    .card--title {
      .sub-title {
        cursor: pointer;
        transition: color 0.3s ease-in-out;
        &:hover {
          color: $primary-color;
        }
      }
    }
  }
  .button-container {
    text-align: center;
  }
}
