.shop-now.show {
  .shop-now-modal {
    visibility: visible;
    opacity: 1;
  }
  .overlay {
    visibility: visible;
  }
  .shop-icon {
    p {
      transform: translateX(0);
      opacity: 1;
    }
    svg {
      circle {
        fill: $primary-color;
      }
    }
  }
}
.shop-now {
  .overlay {
    position: fixed;
    visibility: hidden;
    z-index: 21;
    background-image: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 0.5),
      rgba(0, 0, 0, 0.5)
    );
  }
  .shop-icon {
    display: flex;
    align-items: center;
    position: fixed;
    top: 85%;
    left: 85%;
    z-index: 1;
    @media (max-width: 991px) {
      left: 80%;
    }
    @media (max-width: 545px) {
      left: 75%;
    }
    @media (max-width: 440px) {
      left: 70%;
    }
    cursor: pointer;
    p {
      transform: translateX(120%);
      opacity: 0;
      transition: all 0.3s ease-in-out;
    }
    svg {
      circle {
        transition: all 0.3s ease-in-out;
      }
      path {
        fill: white;
        transition: all 0.3s ease-in-out;
      }
    }
    &:hover {
      p {
        transform: translateX(0);
        opacity: 1;
      }
      svg {
        circle {
          fill: $primary-color;
        }
      }
    }
  }

  .shop-now-modal {
    padding: 25px;
    display: flex;
    flex-direction: column;
    gap: 15px;
    align-items: center;
    width: 560px;
    background-color: $black-shade-1;
    border-radius: 20px;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 22;
    transition: opacity 0.3s ease-in-out;
    visibility: hidden;
    opacity: 0;
    @media (max-width: 600px) {
      flex-direction: column;
      gap: 15px;
      width: calc(100% - 40px);
    }
    .topic-regular {
      font-size: 19px;
    }
    .header-box {
      margin-inline: auto;
    }
    .sub-head {
      margin-top: 10px;
      @media (max-width: 600px) {
        text-align: center;
      }
    }
    .small-text {
      color: $primary-color;
      font-weight: 600;
      font-size: 19px;
    }
    .content-wrapper {
      display: flex;
      justify-content: space-between;
      width: 100%;
      margin-top: 15px;
      @media (max-width: 600px) {
        flex-wrap: wrap;
      }
      .shop-contact {
        @media (max-width: 600px) {
          display: flex;
          gap: 10px;
          margin-bottom: 15px;
          flex-wrap: wrap;
          margin-inline: auto;
        }
        .content {
          display: flex;
          gap: 10px;
          overflow: hidden;
          width: 135%;
          &:hover {
            .shop-call {
              transform: translateX(-250%);
              &::after {
                transform: translateX(129%);
              }
            }
          }
          .shop-call {
            display: inline;
            transform: translateX(0);
            position: relative;
            transition: transform 0.5s ease-in-out;
            &::after {
              content: "+9779862265754";
              transform: translateX(200%);
              display: inline;
              position: absolute;
              right: 0;
              transition: all 0.5s ease-in-out;
            }
          }
        }
        a {
          display: block;
        }
        a + a {
          margin-top: 15px;
          @media (max-width: 600px) {
            margin-top: 0;
          }
        }
      }
      .vendor-wrapper {
        // align-self: center;
        text-align: center;
        @media (max-width: 600px) {
          margin-inline: auto;
          margin-top: 19px;
        }
      }
      .button-wrapper {
        display: flex;
        margin-top: 15px;
        gap: 10px;
      }
      .button-container {
        .image-container {
          width: 135px;
          height: 40px;
          padding: 10px 26px;
          border-radius: 5px;
          background-size: 100% 200%;
          background-image: linear-gradient(
            to bottom,
            #303030 0%,
            #303030 51%,
            #874f47 51%,
            #874f47 101%
          );
          transition: background 0.3s ease-in-out;
          text-align: center;
          &:hover {
            background-position: 0% 100%;
          }
          img {
            width: 50px;
            height: 14px;
          }
        }
      }
    }
    .column {
      display: flex;
      flex-direction: column;
      gap: 21px;
      &:nth-child(2) {
        .button-container {
          @media (max-width: 510px) {
            max-width: 145px;
          }
        }
      }
      .button-container {
        .image-container {
          width: 135px;
          height: 40px;
          padding: 10px 26px;
          border-radius: 5px;
          background-size: 100% 200%;
          background-image: linear-gradient(
            to bottom,
            #303030 0%,
            #303030 51%,
            #874f47 51%,
            #874f47 101%
          );
          transition: background 0.3s ease-in-out;
          text-align: center;
          &:hover {
            background-position: 0% 100%;
          }
          img {
            width: 50px;
            height: 14px;
          }
        }
      }
      .shop-contact {
        a {
          display: block;
          &:not(:last-child) {
            margin-bottom: 12px;
          }
        }
      }
      .content {
        display: flex;
        gap: 10px;
        align-items: center;
        &:hover {
          p {
            color: $primary-color;
          }
        }
      }
    }
    .close-icon {
      position: absolute;
      top: 5px;
      left: 90%;
      cursor: pointer;
      svg {
        transition: opacity 0.3s ease-in-out;
      }
    }
    .icon-container {
      width: 30px;
    }
  }
}
