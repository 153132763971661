.section-special-event{
    margin-bottom: 157px;
    @media(max-width: 820px){
        margin-bottom: 75px;
    }
    .p-right{
        padding-right: 18px;
        @media (max-width: 767px) {
            margin-bottom: 35px;
            padding-right: 0;
        }
    }
  
    .p-left{
        padding-left: 18px;
        @media(max-width:767px){
            padding:0px;
        }
    }
}