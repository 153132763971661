.menu-container1 {
  display: flex;
  justify-content: space-between;
  margin-bottom: 37px;
  @media (max-width: 991px) {
    flex-direction: column;
    row-gap: 10px;
  }
  .text-container {
    .semibold-text {
      color: $text-color;
    }
  }
  .filter-container {
    display: flex;
    column-gap: 9px;
    align-items: center;
    .filter-label {
      p {
        font-size: 16px;
        line-height: 1.2;
      }
    }
    .select-container {
      width: 211px;
      max-width: 100%;
      height: 37px;
      position: relative;
      z-index: 1;
      .drop-arrow {
        position: absolute;
        top: 9%;
        left: 90%;
        &:hover {
          cursor: pointer;
        }
      }
      .dropdown-select {
        list-style: none;
        margin-bottom: 0;
        padding: 0;
        background-color: $secondary-color;

        // &::after{
        //     content: '';
        //     background-image: url(images/arrow-down.svg);
        // }
        .selected {
          font-size: 16px;
          line-height: 1.2;
          padding: 5px;
          border: solid 1px #707070;
          border-radius: 5px;
          cursor: pointer;
        }
        li {
          font-size: 16px;
        }
        li {
          &:not(.selected) {
            display: none;
            // visibility: hidden;
            height: 0;
            transition: all 0.3s ease-in-out;
          }
        }
      }
      .dropdown-select.show-dropdown {
        li {
          &:last-child {
            border-bottom: solid 1px #707070;
          }
          &:not(.selected) {
            display: block;
            // visibility: visible;
            height: 100%;
            padding: 8px;
            border-left: solid 1px #707070;
            border-right: solid 1px #707070;
            color: $white-shade-1;
            // border-bottom: solid 1px #707070;
          }
          &:hover {
            cursor: pointer;
            background-color: $primary-color;
          }
        }
      }

      select {
        width: 100%;
        aspect-ratio: 211 / 37;
        outline: none;
      }
    }
  }
}

.grid {
  display: flex;
  flex-wrap: wrap;
  gap: 2%;
}
@keyframes scale-in {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
.grid-sizer,
.grid-item {
  width: 32%;
  flex-basis: 32%;
  max-height: 379px;
  transition: all 0.5s ease-in-out;
  animation: scale-in 0.5s ease-in-out;
  @media (max-width: 991px) {
    width: 45%;
    flex-basis: 48%;
    margin-bottom: 15px;
  }
  @media (max-width: 661px) {
    width: 100%;
  }
  @media (max-width: 450px) {
    flex-basis: 100%;
  }
}

.gutter-sizer {
  width: 2%;
}
