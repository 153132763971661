footer {
  .repeat-grid {
    margin-bottom: 90px;
    @media (max-width: 991px) {
      margin-bottom: 50px;
    }
    img {
      height: 57px;
      object-fit: cover;
    }
  }
  .container {
    margin-bottom: 50px;
    // @media (max-width: 767px) {
    //   position: relative;
    // }
  }
  .footer-logo {
    width: 123px;
    max-width: 100%;
    @media (max-width: 991px) {
      width: 90px;
    }
    @media (max-width: 575px) {
      margin-left: auto;
      margin-right: auto;
    }
    img {
      aspect-ratio: 1;
      object-fit: cover;
    }
  }
  .footer-content {
    margin-top: 32px;

    @media (max-width: 575px) {
      text-align: center;
    }
    &.products {
      margin-left: 43px;
      @media (max-width: 767px) {
        margin-left: 0;
      }
    }
    &.contact {
      margin-left: 93px;
      @media (max-width: 767px) {
        margin-left: 0;
      }
    }
    &.up-arrow {
      margin-left: 8px;
    }
  }
  .header-box {
    margin-bottom: 17px;
  }
  ul {
    list-style: none;
    padding: 0;
    margin: 0;
    li {
      &:not(:last-child) {
        margin-bottom: 10px;
      }
      a {
        &:hover {
          color: $primary-color;
        }
      }
    }
  }
  .footer-link {
    color: $primary-color;
  }
  .footer-contact {
    display: flex;
    margin-bottom: 8px;
    word-break: break-all;
    @media (max-width: 575px) {
      justify-content: center;
    }
    .icon {
      width: 15.43px;
      margin-right: 8px;
      //   padding-top: 5px;
    }
    .text-container {
      p {
        font-size: 18px;
        color: $primary-color;
      }
    }
  }
  .social-media-icon {
    ul {
      margin-top: 17px;
      display: flex;
      gap: 8px;
      @media (max-width: 575px) {
        justify-content: center;
      }
    }
    .icon {
      &:hover {
        cursor: pointer;
        svg {
          fill: $primary-color;
          transform: translateY(-5px) scale(1.2);
        }
      }
      svg {
        transition: all 0.3s ease-in-out;
      }
    }
  }
  .footer-banner {
    text-align: center;
    padding: 10px 0;
    background-color: $secondary-color;
    p {
      color: $primary-color;
    }
  }
  .up-arrow {
    list-style: none;
    // transform: translateY(-50%);
    transition: all 0.3s ease-out;
    transform-origin: bottom left;
    @media (max-width: 767px) {
      margin-left: 0;
      position: absolute;
      top: -5%;
      left: 90%;
    }
    @media (max-width: 575px) {
      left: 85%;
    }
    &:hover {
      cursor: pointer;
      transform: scale(1.2);
    }
  }
}
