.section-banner {
  height: calc(100vh - 98px);
  display: flex;
  max-height: 1060px;
  flex-direction: column;
  // gap: 20px;
  // justify-content: space-between;
  // position: relative;

  .banner-container {
    position: relative;

    height: 1px;
    flex-grow: 1;
  }
  .overlay {
    background-image: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 0.3),
      rgba(0, 0, 0, 0.3)
    );
    z-index: 1;
  }
  .banner--text-container {
    width: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 2;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    @media (max-width: 767px) {
      padding-block: 30px;
    }
    .header-box {
      margin-bottom: 8px;
      &.underline {
        &:after {
          background-color: white;
          // margin-top: 12px;
        }
      }
    }
    .sub-head {
      max-width: 490px;
      margin-bottom: 41px;
    }
  }
  .slick-initialized {
    position: relative;
    // max-height: 460px;
    div {
      height: 100%;
    }
    height: 100%;
    .slick-list {
      height: 100%;
      .slick-track {
        height: 100%;
        .content {
          height: 100%;
          .image-container {
            height: 100%;
          }
        }
      }
    }
    .slick-slide {
      // height: 60vh;
      @media (min-width: 1450px) {
        // height: 80vh;
      }
    }
    .content {
      position: relative;
      .image-container {
        // height: 60vh;
        @media (min-width: 1450px) {
          // height: 80vh;
        }
        img {
          object-fit: cover;
          object-position: center;
          height: 100%;
          // object-position: top;
          // aspect-ratio: 16/9;
          // height: 60vh;
        }
      }
    }
  }
  .slick-dots {
    bottom: 20px;
    z-index: 2;
    @media (max-width: 575px) {
      display: none;
    }
    li {
      button {
        &::before {
          font-size: 12px;
          color: white;
        }
      }
    }
  }
}
.slick-dotted.slick-slider {
  margin-bottom: 0;
}
