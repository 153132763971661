.section-packaging {
  margin-bottom: 125px;
  @media (max-width: 767px) {
    margin-bottom: 90px;
  }
  .slick-initialized {
    margin-top: 60px;
    .image-container {
      max-width: 600px;
      margin-inline: auto;
    }
    .slick-arrow {
      z-index: 1;
      @media (max-width: 767px) {
        display: none !important;
      }
    }
    .slick-prev {
      left: 10%;
      top: 35%;
      // &::before {
      // content: url("../../images/prev.png");
      // }
    }
    .slick-next {
      right: 10%;
      top: 35%;
      // &::before {
      // content: url("../../images/next.png");
      // }
    }
    .slick-dots {
      bottom: -15%;
      left: 0%;
      li {
        button {
          &::before {
            color: $primary-color;
            opacity: 0.9;
            font-size: 10px;
          }
        }
        &.slick-active {
          button {
            border: solid 1px $primary-color;
            border-radius: 50%;
            transition: all 0.3s ease-in-out;
            // &::before {
            //   border: solid 1px $primary-color;
            //   border-radius: 50%;
            //   width: 20px;
            //   opacity: 1;
            // }
          }
        }
      }
    }
  }
}

.section-form.sec-form.show-form {
  .container,
  .overlay {
    visibility: visible;
    opacity: 1;
  }
}
.section-form.sec-form {
  margin-bottom: 125px;
  @media (max-width: 767px) {
    margin-bottom: 50px;
  }
  h3 {
    font-size: 28px;
    @media (max-width: 767px) {
      font-size: 20px;
    }
  }
  .container,
  .overlay {
    visibility: hidden;
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
  }
  .overlay {
    position: fixed;
    z-index: 1;
    background-image: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 0.5),
      rgba(0, 0, 0, 0.5)
    );
  }
  .order-banner {
    padding: 29px 35px;
    max-width: 839px;
    margin-inline: auto;
    background-color: #121111;
    display: flex;
    gap: 30px;
    @media (max-width: 575px) {
      flex-wrap: wrap;
      justify-content: center;
    }
    .btn-main {
      padding: 13px 13px 15px;
      margin-bottom: 0;
      flex-shrink: 0;
      align-self: center;
    }
  }
  .container {
    max-width: 900px;
    padding: 40px 60px;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 5;
    .close-icon {
      position: absolute;
      top: 2%;
      left: 95%;
      cursor: pointer;
      svg {
        transition: opacity 0.3s ease-in-out;
      }
    }
    .form-container {
      margin-bottom: 0;
      margin-top: 40px;
    }
    .form-submit {
      margin-top: 30px;
    }
  }
}
.customizedSlickArrowReact {
  &::before {
    display: none;
  }
}
