.btn-main {
  //   background-color: #303030;
  color: white;
  font-size: 18px;
  line-height: 1.214;
  font-family: "Segoe UI", sans-serif;
  padding: 16.5px 41px 16.5px 41px;
  font-weight: 600;
  border-radius: 5px;
  transition: all 0.3s ease-in-out;
  border: none;
  background-size: 100% 200%;
  background-image: linear-gradient(
    to bottom,
    $grey-shade-2 0%,
    $grey-shade-2 51%,
    $primary-color 51%,
    $primary-color 100%
  );
  &:hover {
    // background-color: $primary-color;
    background-position: 0% 100%;
  }
  svg {
    margin-right: 7px;
    width: 17px;
  }
}
.button-container1 {
  display: flex;
  width: 302px;
  height: 62px;
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
  a {
    width: 100%;
  }
}
.bt1 {
  @media (max-width: 991px) {
    .btn-main {
      display: flex;
      align-items: center;
      svg {
        margin-top: 5px;
      }
    }
  }
}
