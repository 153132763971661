.section-production-process {
  .header {
    text-align: center;
    margin-bottom: 80px;
    @media (max-width: 991px) {
      margin-bottom: 45px;
    }
  }
  .underline {
    &::after {
      margin-left: 0;
      @media (max-width: 767px) {
        margin-left: auto;
      }
    }
  }
  .text-container {
    margin-bottom: 97px;
    @media (max-width: 767px) {
      margin-bottom: 50px;
    }
    &.even {
      margin-left: -66px;
      @media (max-width: 1130px) {
        margin-left: 0;
      }
    }
    .header-box {
      margin-bottom: 26px;
      @media (max-width: 767px) {
        margin-bottom: 15px;
        text-align: center;
      }
    }
    .read-more-link {
      display: inline-block;
      margin-top: 15px;
      font-size: 18px;
      color: $primary-color;
    }
  }
  .image-container {
    max-width: 477px;
    margin-bottom: 97px;
    @media (max-width: 1130px) {
      max-width: 100%;
    }
    @media (max-width: 767px) {
      margin-bottom: 50px;
    }
    &.odd {
      margin-left: 68px;
      @media (max-width: 1130px) {
        margin-left: 0;
      }
    }
    img {
      aspect-ratio: 477/357;
      object-position: center;
      object-fit: cover;
    }
  }
}
