.page-testimonials {
  .section-secondary-banner {
    .header-box {
      max-width: 431px;
    }
  }
  .section-testimonial {
    margin: 75px 0;
    background-color: $secondary-color;
    h3 {
      @media (max-width: 400px) {
        font-size: 30px;
      }
    }
    .testimonial-container {
      justify-content: center;

      max-width: 549px;
      padding-bottom: 40px;
      height: 100%;
      @media (max-width: 767px) {
        margin-left: auto;
        margin-right: auto;
      }
      .testimonial-content {
        background-color: $black-shade-1;
        padding: 34px 26px 26px 43px;
        .client-header {
          @media (max-width: 991px) {
            flex-direction: column;
            gap: 50px;
            align-items: center;
          }
          .client-quote-title {
            svg {
              z-index: 0;
            }
            .client-quote-title-text {
              position: relative;
            }
          }
        }
      }
    }
  }
}
