.section-product-group{
    margin-top: 61px;
    margin-bottom: 100px;
    .content-container{
        li{
            list-style: none;
            a{
                text-decoration: none;
            }
        }
        .card{
            .image-container{
                max-width: 500px;
                margin-bottom: 20px;
                img{
                    aspect-ratio: 1; 
                } 
            }
            .text-container{
                margin-left: auto;
                margin-right: auto;
                max-width: 230px;
                .card--title{
                    text-align: center;
                }
                .text-dark{                  
                    font-size: 20px;                   
                }
            }
        }
    }
}