// -----------------------------------------------------------------------------
// This file contains very basic styles.
// -----------------------------------------------------------------------------

/**
 * Set up a decent box model on the root element
 */
html {
  box-sizing: border-box;
}

/**
 * Make all elements from the DOM inherit from the parent box-sizing
 * Since `*` has a specificity of 0, it does not override the `html` value
 * making all elements inheriting from the root box-sizing value
 * See: https://css-tricks.com/inheriting-box-sizing-probably-slightly-better-best-practice/
 */
*,
*::before,
*::after {
  box-sizing: inherit;
}

/**
 * Basic styles for links
 */
a {
  color: $brand-color;
  text-decoration: none;

  @include on-event {
    color: $text-color;
    text-decoration: underline;
  }
}
* {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
}
body {
  box-sizing: border-box;
}
img {
  width: 100%;
  height: auto;
  filter: brightness(1.1);
}
h3 {
  color: white;
}

.border {
  border: 2px solid purple !important;
}

.container {
  max-width: 1195px;
}

@media (min-width: 1200px) {
  .container {
    max-width: 1195px;
  }
}

body.show {
  @media (max-width: 991px) {
    overflow: hidden;
    .menu-toggle {
      span {
        &:first-child {
          transform-origin: left top;
          transform: translate(0px, 0px) rotate(45deg);
          width: 45px;
          @media (max-width: 575px) {
            width: 40px;
            transform: translate(6px, 0px) rotate(45deg);
          }
        }
        &:nth-child(2) {
          display: none;
        }
        &:nth-child(3) {
          transform: translate(-3px, 0px) rotate(-45deg);
          transform-origin: left top;
          width: 45px;
          @media (max-width: 575px) {
            width: 40px;
            transform: translate(3px, -3px) rotate(-45deg);
          }
        }
      }
    }
  }
}
body.modal-opened {
  height: 100vh;
  overflow-y: hidden;
  @media (max-width: 767px) {
    overflow-y: initial;
  }
}
body.modal-opened-video {
  height: 100vh;
  overflow-y: hidden;
}
a {
  &:hover,
  &:active,
  &:focus {
    color: $primary-color;
    text-decoration: none;
  }
}
.order-firsts {
  @media (max-width: 767px) {
    order: 1;
  }
}
.order-second {
  @media (max-width: 767px) {
    order: 2;
  }
}
.order-third {
  @media (max-width: 767px) {
    order: 3;
  }
}
.order-fourth {
  @media (max-width: 767px) {
    order: 4;
  }
}
.order-fifth {
  @media (max-width: 767px) {
    order: 5;
  }
}
.order-sixth {
  @media (max-width: 767px) {
    order: 6;
  }
}
svg {
  transition: all 0.3s ease-in-out;
}

.blogs {
  .sub-topic,
  .text-container {
    max-width: 80%;
    margin-inline: auto;
  }
}
