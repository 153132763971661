.section-testimonial {
  padding-top: 64px;
  padding-bottom: 30px;
  background-color: $black-shade-1;
  .container {
    position: relative;
  }
  .slick-initialized {
    width: 50%;
    @media (max-width: 991px) {
      width: 100%;
    }
    .slick-dots {
      bottom: 18%;
      left: 0%;
      text-align: left;
      @media (max-width: 991px) {
        text-align: center;
        bottom: 10%;
      }
      li {
        button {
          &::before {
            // width: 0;
            color: $primary-color;
            opacity: 0.9;
            font-size: 10px;
          }
        }
        &.slick-active {
          button {
            border: solid 1px $primary-color;
            border-radius: 50%;
            transition: all 0.3s ease-in-out;
            // &::before {
            //   border: solid 1px $primary-color;
            //   border-radius: 50%;
            //   width: 20px;
            //   opacity: 1;
            // }
          }
        }
      }
    }
  }
  .testimonial-container {
    display: flex;
    padding-bottom: 138px;
    @media (max-width: 991px) {
      justify-content: center;
    }
    .testimonial-content {
      .client-header {
        display: flex;
        gap: 119px;
        margin-bottom: 40px;
        @media (max-width: 767px) {
          flex-direction: column;
          gap: 50px;
        }
        .client-image {
          width: 105px;
          height: 105px;
          @media (max-width: 767px) {
            align-self: center;
          }
          img {
            aspect-ratio: 1;
            object-fit: cover;
            border-radius: 50%;
          }
        }
        .client-quote-title {
          position: relative;
          max-width: 251px;
          align-self: center;
          svg {
            position: absolute;
            top: 0%;
            z-index: -1;
            left: -43%;
            @media (max-width: 467px) {
              left: -25%;
            }
            @media (max-width: 372px) {
              left: -23%;
            }
            path {
              fill: $grey-shade-2;
            }
          }
          h3 {
            @media (max-width: 467px) {
              margin-left: 20px;
            }
          }
        }
      }
      .client-quote-container {
        .client-quote {
          max-width: 534px;
          margin-bottom: 20px;
        }
        .review-platform {
          p {
            color: $primary-color;
          }
        }
      }
    }
  }
  .product-images {
    // position: relative;
    position: absolute;
    left: 64.48%;
    top: 0;
    @media (max-width: 1199px) {
      margin-left: auto;
    }
    @media (max-width: 991px) {
      display: none;
    }
    .image-container {
      transition: all 0.3s ease-in-out;
      // &:hover{
      //     z-index: 1;
      //     transform: scale(1.05);
      // }
      img {
        border-radius: 15px;
      }
    }
    .image-container:first-child {
      width: 355px;
      position: relative;
      img {
        aspect-ratio: 1;
        object-fit: cover;
      }
    }
    .image-container:nth-child(2) {
      width: 250px;
      position: absolute;
      bottom: -27%;
      left: -17%;

      img {
        aspect-ratio: 1;
        object-fit: cover;
      }
    }
  }
  .button-container1 {
    a {
      width: 100%;
      button {
        width: 100%;
        height: 100%;
      }
    }
  }
}
