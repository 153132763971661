body {
  font-size: 18px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 1.25;
  font-family: "Montserrat", sans-serif;
  background-color: $secondary-color;
}
h1 {
  font-size: 40px;
  color: white;
  font-weight: 700;
  margin: 0;
  @media (max-width: 991px) {
    font-size: 35px;
  }
  @media (max-width: 767px) {
    font-size: 30px;
  }
  @media (max-width: 575px) {
    font-size: 25px;
  }
}
h2 {
  font-size: 36px;
  font-weight: 600;
  margin-bottom: 0;
  color: $primary-color;
  @media (max-width: 991px) {
    font-size: 30px;
  }
  @media (max-width: 767px) {
    font-size: 25px;
  }
}
h3 {
  font-size: 30px;
  line-height: 1.67;
  color: $primary-color;
  margin: 0;
  @media (max-width: 991px) {
    font-size: 24px;
  }
}
h4 {
  font-size: 24px;
  font-weight: 600;
  line-height: 1.5;
  color: $primary-color;
  margin-bottom: 0;
  @media (max-width: 767px) {
    font-size: 20px;
  }
}
h5 {
  font-size: 22px;
  font-weight: 400;
  margin-bottom: 0;
  color: white;
  @media (max-width: 767px) {
    font-size: 18px;
  }
}
p {
  font-size: 20px;
  line-height: 1.5;
  color: $white-shade-1;
  margin: 0;
  @media (max-width: 991px) {
    font-size: 18px;
  }
  @media (max-width: 767px) {
    font-size: 18px;
  }
}
.text-dark {
  font-size: 18px;
  line-height: 1.22;
  color: black !important;
  font-weight: bold;
}
.sub-title {
  color: white;
  font-size: 24px;
  font-weight: 600;
  @media (max-width: 991px) {
    font-size: 22px;
  }
  @media (max-width: 767px) {
    font-size: 18px;
  }
}
a {
  font-size: 16px;
  line-height: 1.2;
}
.small-text {
  font-size: 16px;
  line-height: 1.19;
  color: $tertiary-color;
}
.small-text-white {
  font-size: 18px;
  line-height: 1.19;
  color: white;
}

.another-a {
  font-size: 20px;
  line-height: 1.33;
  text-decoration: none;
  color: white;
  transition: all 0.3s ease-in-out;
  &:hover {
    color: $primary-color;
  }
}
.extra-small {
  font-size: 12px;
  line-height: 1.5;
  color: $primary-color;
}
.text-style {
  font-size: 16px;
  line-height: 1.25;
  color: $grey-shade-2;
  font-weight: 400;
}
.para-text {
  font-size: 16px;
  line-height: 1.175;
  color: $grey-shade-2;
  font-weight: 400;
}
input,
textarea {
  padding-top: 17px;
  padding-left: 21px;
  padding-bottom: 16px;
  border-radius: 5px;
  border: solid 1px $secondary-color;
  background-color: $grey-shade-3;
  color: $grey-shade-1;
  outline: none;
  margin-bottom: 16px;
}
.sidebar-text {
  font-size: 15px;
  line-height: 1.47;
  color: $grey-shade-2;
}
.semibold-text {
  font-size: 24px;
  font-weight: 600;
  line-height: 1.5;
  color: $secondary-color;
}
.topic-regular {
  font-size: 24px;
  line-height: 1.5;
  color: $white-shade-1;
}
.caption {
  font-size: 18px;
  line-height: 1.5;
  color: $white-shade-1;
}
