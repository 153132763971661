.section-luxury {
  margin-bottom: 210px;
  @media (max-width: 991px) {
    margin-bottom: 75px;
  }
  img {
    border-radius: 10px;
  }
  .image-container-big {
    max-width: 680px;
    @media (max-width: 767px) {
      margin-bottom: 30px;
    }
    img {
      aspect-ratio: 1.22;
      object-fit: cover;
      object-position: center;
    }
  }
  .text-container {
    max-width: 363px;
    margin-bottom: 18px;
    @media (max-width: 767px) {
      max-width: 100%;
      text-align: center;
    }
  }
  .underline {
    &::after {
      margin-left: initial;
      @media (max-width: 767px) {
        margin-left: auto;
      }
    }
  }
  .para {
    max-width: 472px;
    margin-bottom: 20px;
    @media (max-width: 767px) {
      max-width: 100%;
    }
  }
  .image-row {
    display: flex;
    justify-content: space-between;
    @media (max-width: 767px) {
      justify-content: center;
      gap: 20px;
    }
    .image-container-small {
      max-width: 219px;
      flex-basis: 48%;
    }
  }
}
