.page-products {
  .card {
    .image-container {
      position: relative;
      .overlay {
        background-image: linear-gradient(
          to bottom,
          transparent,
          rgba(0, 0, 0, 0.5)
        );
      }
      img {
        filter: brightness(1.1);
        aspect-ratio: 298/300;
      }
    }
  }
  .section-products {
    margin-top: 61px;
    .card-container {
      width: 100%;
    }
    .container {
      display: flex;
      gap: 34px;
      margin-bottom: 100px;
      @media (max-width: 767px) {
        margin-bottom: 75px;
      }
      @media (max-width: 767px) {
        flex-direction: column;
      }
    }
    .menu-container {
      display: flex;
      gap: 15px;
      margin-bottom: 30px;
      .menu-icon {
        background-color: #e3e3e3;
        width: 40px;
        height: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        &:hover {
          cursor: pointer;
        }
      }
    }

    .card-row {
      ul {
        padding: 0px;
        // display: flex;
        gap: 14px;
        flex-wrap: wrap;
        @media (max-width: 909px) {
          justify-content: center;
        }

        // @media (max-width: 614px) {
        //     flex-wrap: wrap;
        //     justify-content: center;

        // }
        li {
          list-style: none;
          // flex: 1 1 272px;
          a {
            &:hover {
              text-decoration: none;
            }
            .card {
              margin-bottom: 40px;
              .image-container {
                margin-bottom: 15px;
                width: 100%;
              }
              .text-container {
                text-align: center;
              }
            }
          }
        }
      }
    }
    &.all-products {
      .card-row {
        ul {
          @media (max-width: 1195px) {
            justify-content: center;
          }
        }
      }
    }

    /*   .card-row1 {
      ul {
        padding: 0px;
        display: flex;
        gap: 14px;
        list-style: none;
        @media (max-width: 614px) {
          flex-wrap: wrap;
          justify-content: center;
        }

        li {
          a {
            &:hover {
              text-decoration: none;
            }
            .card {
              margin-bottom: 40px;
              .image-container {
                margin-bottom: 15px;
                max-width: 500px;
                img {
                  aspect-ratio: 139/100;
                  object-fit: cover;
                  object-position: center;
                }
              }
              .text-container {
                text-align: center;
              }
            }
          }
        }
      }
    } */
  }
  .other-products {
    margin-top: 50px;
    .header-box {
      text-align: center;
      margin-bottom: 15px;
    }
    .down-arrow {
      text-align: center;
      svg {
        transition: all 0.3s ease-in-out;
        cursor: pointer;
      }
      &:hover {
        svg {
          transform: scale(1.1);
        }
      }
    }
    .product-container {
      margin-top: 75px;
      display: flex;
      flex-wrap: wrap;
      gap: 2%;
      height: 0;
      opacity: 0;
      a {
        flex-basis: 32%;
        @media (max-width: 991px) {
          flex-basis: 48%;
          margin-bottom: 15px;
        }
        @media (max-width: 450px) {
          flex-basis: 100%;
        }
      }
      .card {
        flex-basis: 32%;
      }
      animation: hide-product 0.3s ease-in-out;
    }
    .product-container.show {
      height: 100%;
      opacity: 1;
      animation: show-product 0.3s ease-in-out;
    }
    @keyframes show-product {
      0% {
        height: 0;
        opacity: 0;
      }
      100% {
        height: 100%;
        opacity: 1;
      }
    }
    @keyframes hide-product {
      0% {
        height: 100%;
        opacity: 1;
      }
      100% {
        height: 0;
        opacity: 0;
      }
    }
  }
}
