.section-video {
  margin: 175px 0;
  @media (max-width: 991px) {
    margin: 75px 0;
  }
  .text-container {
    margin-inline: auto;
    max-width: 485px;
    margin-bottom: 25px;
    @media (max-width: 767px) {
      max-width: 100%;
      text-align: center;
    }
  }
  .video-container {
    max-width: 825px;
    // max-height: 386px;
    // aspect-ratio: 16/9;
    margin-inline: auto;
    position: relative;
    text-align: center;
    iframe {
      max-width: 853px;
      width: 100%;
      aspect-ratio: 16/9;
      // height: 480px;
    }
    .overlay {
      background-size: cover;
      background-position: center;
      position: absolute;
      top: 0px;
      left: 0px;
      width: 100%;
      height: 100%;
    }
    // iframe {
    //   width: 100%;
    // }
    .video-container-detail {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 350px;
      max-width: 100%;
      margin-left: auto;
      margin-right: auto;
      text-align: center;
      .text-container {
        h3 {
          color: $white;
          @media (max-width: 400px) {
            font-size: 15px;
          }
        }
      }
      .play-pause {
        display: flex;
        justify-content: center;
        margin-top: 36px;
        .play-container {
          width: 58px;
          cursor: pointer;
          @media (max-width: 400px) {
            width: 40px;
          }
        }
        .pause-container {
          display: none;
          width: 58px;
          cursor: pointer;
          img {
            height: 58px;
          }
        }
      }
    }
    .underline {
      &:after {
        content: "";
        background-color: $white;
      }
    }
  }
  .hide {
    .overlay {
      display: none;
    }
    .video-container-detail {
      display: none;
    }
  }
  video {
    // height: 95vh;
    // aspect-ratio: 16/9;
    max-height: 386px;
    @media (max-width: 820px) {
      height: auto;
    }
  }
}
.section-video.show-modal {
  .video-modal {
    visibility: visible;
    opacity: 1;
  }
}
.video-modal {
  padding: 32px;
  border-radius: 8px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: $grey-shade-2;
  z-index: 1;
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
  .image-container {
    max-width: 720px;
    margin-bottom: 32px;
    img {
      aspect-ratio: 1.6;
      object-fit: cover;
    }
  }
  iframe {
    width: 100%;
    aspect-ratio: 16/9;
  }
  .header-box {
    margin-bottom: 24px;
  }
  .icon {
    width: 48px;
    cursor: pointer;
  }
}
