.section-team {
  padding-bottom: 88px;
  margin-top: 115px;
  @media (max-width: 991px) {
    margin-top: 25px;
    padding-bottom: 75px;
  }
  .container {
    @media (min-width: 1200px) {
      max-width: 1159px;
    }
  }
  .text-container {
    text-align: center;
    margin-bottom: 58px;
    .header-box {
      &.underline {
        &::after {
          margin-top: 18px;
          margin-bottom: 26px;
        }
      }
    }
    .para {
      max-width: 628px;
      margin-left: auto;
      margin-right: auto;
    }
  }
  .member-row {
    display: flex;
    gap: 45px;
    flex-wrap: wrap;
    justify-content: center;
    .team-content {
      .image-content {
        max-width: 250px;
        margin-bottom: 18px;
        img {
          aspect-ratio: 1;
          object-fit: cover;
          object-position: center;
          border-radius: 9px;
        }
      }
      .text-container {
        .header {
          p {
            font-weight: bold;
          }
        }
      }
    }
  }
}
