.sidebar {
  width: 266px;
  height: auto;
  background-color: $black-shade-1;
  flex-shrink: 0;
  margin-bottom: 40px;
  @media (max-width: 767px) {
    width: 100%;
  }
  h5 {
    color: $primary-color;
    font-weight: bold;
  }
  p {
    color: $grey-shade-1;
  }
  .text-container {
    padding-left: 25px;
    padding-top: 26px;
    margin-right: 25px;
    .header-box {
      margin-bottom: 18px;
    }
  }
  ul {
    padding-left: 25px;
    list-style: none;
    li {
      margin-bottom: 10px;
      a {
        color: $white-shade-1;
        font-size: 18px;
        position: relative;
        &::after {
          content: "";
          position: absolute;
          left: 0;
          bottom: -5px;
          width: 0%;
          height: 3px;
          display: inline-block;
          background-color: $primary-color;
          transition: all 0.3s ease-in-out;
        }
        &:hover {
          color: $primary-color;
          &::after {
            width: 100%;
          }
        }
      }
      a.active {
        color: $primary-color;
        &::after {
          width: 100%;
        }
      }
    }
  }
}
