.section-gallery {
  margin-top: 139px;
  margin-bottom: 139px;
  @media (max-width: 991px) {
    margin-bottom: 75px;
    margin-top: 75px;
  }
  .image-container {
    margin-bottom: 20px;
    img {
      aspect-ratio: 1;
      object-fit: cover;
    }
  }
  .stay-touch-container {
    background-color: $black-shade-1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    aspect-ratio: 1 / 0.47;
    margin-bottom: 20px;
    .text-container {
      text-align: center;
      // &.underline{
      //     &::after{
      //         margin-top: 14px;
      //     }
      // }
    }
    .button-container {
      display: flex;
      margin-top: 21px;
      margin-bottom: 23px;
      column-gap: 20px;
      justify-content: center;
      @media (max-width: 882px) {
        .btn-main {
          padding-left: 28px;
          padding-right: 28px;
        }
      }
      @media (max-width: 500px) {
        flex-direction: column;
        gap: 20px;
        align-items: center;
      }
    }
    .a-container {
      display: flex;
      column-gap: 24px;
      justify-content: center;
      @media (max-width: 960px) {
        flex-wrap: wrap;
        row-gap: 15px;
      }
      @media (max-width: 500px) {
        flex-direction: column;
        gap: 20px;
        align-items: center;
      }
      .a1,
      .a2 {
        display: flex;
        align-items: center;
        gap: 7px;
        &:hover {
          svg {
            stroke: $primary-color;
          }
        }
        svg {
          transition: all 0.3s ease-in-out;
        }
      }
    }
  }
  .button-container1 {
    margin-top: 26px;
    a {
      width: 100%;
      button {
        width: 100%;
        height: 100%;
      }
    }
  }
}
.gallery-hide {
  @media (max-width: 767px) {
    display: none;
  }
}
