.section-our-story {
  margin-top: 60px;
  margin-bottom: 100px;
  // .container{
  //     @media (min-width: 1200px) {
  //         max-width: 1220px;
  //     }
  // }
  @media (max-width: 991px) {
    padding-bottom: 75px;
  }
  h2 {
    font-weight: 400;
  }
  .header-box {
    text-align: center;
    max-width: 878px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 103px;
    @media (max-width: 991px) {
      margin-bottom: 50px;
    }
  }
  .image-container.aux {
    @media (min-width: 1050px) {
      height: 100%;
    }
    img {
      @media (min-width: 1050px) {
        height: 100%;
      }
    }
  }
  .image-container {
    max-width: 490px;
    @media (max-width: 767px) {
      margin-bottom: 20px;
      margin-inline: auto;
    }
    img {
      aspect-ratio: 490/561;
      object-fit: cover;
    }
  }
  .product-description {
    margin-top: 100px;
    @media (max-width: 991px) {
      margin-top: 50px;
    }
    .image-container {
      max-width: 669px;
      img {
        aspect-ratio: 1;
        object-fit: cover;
      }
    }
    .prodcut-description-info {
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;

      .content {
        display: flex;
        gap: 18px;
        @media (max-width: 767px) {
          margin-inline: auto;
        }
        &:not(:last-child) {
          margin-bottom: 70px;
        }
        .text-container {
          max-width: 212px;
          p {
            color: $primary-color;
            font-weight: 700;
          }
          .small-text {
            color: $white-shade-1;
            font-weight: 400;
          }
        }
      }
    }
  }
  /* .section-our-story-detail{
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      .box-header{
          width: 754px;
          max-width: 100%;
          margin-bottom: 52px;
          span{
              color: $primary-color;
          }
      }
      .p-container{
          width: 750px;
          max-width: 100%;
      }
      .image-container{
          width: 100%;
          margin-top: 60px;
          margin-bottom: 60px;
          img{
              aspect-ratio: 809/327;
              object-fit: cover;
              border-radius: 15px;
          }
      }
  } */
}
