.section-product-details {
  margin-top: 80px;
  margin-bottom: 90px;
  @media (max-width: 767px) {
    margin: 50px 0;
  }

  li {
    margin-bottom: 7px;
  }
  .image-section {
    margin-top: 25px;
    .image-container {
      max-width: 267px;
      margin-left: auto;
      @media (max-width: 767px) {
        max-width: 100%;
      }
    }
  }
  .image-section-custom {
    // display: flex;
    // flex-direction: column;
    // justify-content: space-between;
    // height: 100%;
    .image-container {
      max-width: 288px;
      @media (max-width: 767px) {
        max-width: 100%;
      }
    }
    .icon-section {
      margin-top: 30px;
    }
  }
  .icon-section {
    display: flex;
    gap: 16px;
    align-items: center;
    // margin-top: 190px;
    margin-top: 30px;
    @media (max-width: 767px) {
      margin: 15px 0 !important;
    }
    .icon-container {
      max-width: 20.5px;
      align-self: center;
      &:hover {
        svg {
          fill: #782f19;
          transform: translateY(-5px) scale(1.2);
        }
      }
      svg {
        fill: white;
        transition: all 0.3s ease-in-out;
      }
    }
  }
  .text-container.text-container-custom {
    .para {
      margin-bottom: 0;
    }
  }
  .text-container {
    // margin-left: 12px;
    display: flex;
    flex-direction: column;
    // height: calc(100% - 63px);
    @media (max-width: 991px) {
      margin-left: 0;
    }
    h1 {
      color: $primary-color;
      line-height: 1.5;
    }
    li {
      margin-left: 20px;
    }
    .header-box {
      &.underline {
        &::after {
          margin-left: 0;
          margin-bottom: 33px;
        }
      }
    }
    .para {
      margin-bottom: 46px;
      @media (max-width: 767px) {
        margin-bottom: 25px;
      }
    }
    .sub-head {
      margin-bottom: 7px;
    }
    .secondary-title {
      margin-bottom: 7px;
    }
    .button-container {
      margin-top: auto;
    }
  }
}
