.section-gallery-container {
  padding-top: 145px;
  @media (max-width: 991px) {
    padding-top: 100px;
  }
  @media (max-width: 767px) {
    padding-top: 50px;
  }
  .slick-dots {
    bottom: initial;
    top: -76px;
    li {
      button {
        &::before {
          color: $grey-shade-1;
          opacity: 1;
        }
        &:hover {
          &::before {
            color: $primary-color;
          }
        }
      }
      &:first-child {
        margin-right: 92px;
        button {
          &:before {
            font-family: "Segoe UI";
            content: "Photo";
            font-size: 24px;
            font-weight: 600;
          }
        }
      }

      &:nth-child(2) {
        button {
          &:before {
            font-family: "Segoe UI";
            content: "Video";
            font-size: 24px;
            font-weight: 600;
          }
        }
      }
    }
    li.slick-active {
      position: relative;
      button {
        &::before {
          padding-bottom: 10px;
          color: $primary-color;
          text-decoration: underline;
          text-decoration-thickness: 3px;
        }
      }
    }
  }
  .sortButtom {
    display: flex;
    justify-content: center;
    column-gap: 40px;
    p {
      font-size: 24px;
      font-weight: 600;
      padding-bottom: 10px;
      cursor: pointer;
    }
    .active {
      p {
        color: #874f47;
        text-decoration: underline;
      }
    }
  }
  /*  .slider-buttom {
    display: flex;
    justify-content: center;
    column-gap: 24px;
    margin-bottom: 40px;
    .slider-buttom-filter {
      width: 134px;
      height: 36px;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;

      .semibold-text {
        color: $grey-shade-1;
      }
    }
  } */
  .sliderContainer {
    margin-top: 32px;
    margin-bottom: 20px;
    &.show {
      .image-gallery {
        display: none;
      }
      .video-gallery {
        display: block;
      }
    }
    .image-gallery {
      overflow: hidden;
      .image-container {
        margin-bottom: 23px;
        cursor: pointer;
        img {
          aspect-ratio: 1;
        }
      }

      .image-gallery-detail.show {
        display: block;
      }
    }
    .video-gallery {
      display: none;
      overflow: hidden;

      .video-container {
        position: relative;
        width: 100%;
        height: 100%;
        margin-bottom: 20px;
        aspect-ratio: 16/9;
        cursor: pointer;
        .img-container {
          height: 100%;
          padding-bottom: 20px;
          img {
            height: 100%;
            object-fit: cover;
            aspect-ratio: 1/1;
          }
        }
        .v-overlay {
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
          .play-container {
            width: 40px;
          }
        }
      }
    }
  }
}
.container {
  position: relative;
}
// .image-gallery-content {
//   display: none;
// }
.image-gallery-detail {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 5;
  visibility: hidden;
  opacity: 0;
  transform: translateY(20px);
  transition: opacity 0.3s ease;
  @media (max-width: 767px) {
    display: none;
  }
  .arrow {
    position: absolute;
    top: 50%;
    width: 20px;
    cursor: pointer;
    img {
      aspect-ratio: initial !important;
      @media (max-width: 991px) {
        max-width: 12px;
      }
    }
  }
  .next-button {
    right: 10px;
  }
  .prev-button {
    left: 10px;
  }
  .image-container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 80%;
    margin: 0 auto;
    img {
      aspect-ratio: 16/9;
      object-fit: cover;
    }
    .icon-close {
      // color: white;
      // font-size: 25px;
      position: absolute;
      right: 70px;
      top: 65px;
      transform: translate(100%, -100%);
      cursor: pointer;
      @media (max-width: 991px) {
        max-width: 35px;
        top: 40px;
        right: 40px;
      }
      img {
        aspect-ratio: initial;
      }
    }
  }
}
.image-gallery-detail.show {
  visibility: visible;
  opacity: 1;
  transform: translateY(0);
}
.video-gallery-detail {
  position: fixed;
  z-index: 5;
  width: 100%;
  left: 0px;
  top: 0px;
  height: 100%;
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s ease;
  .video-container {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 80%;

    margin-top: auto;
    margin-bottom: auto;
    .icon-close {
      color: white;
      font-size: 25px;
      position: absolute;
      right: 0;
      top: 0;
      transform: translate(100%, -100%);
      cursor: pointer;
    }
    iframe {
      width: 100%;
      aspect-ratio: 16/8;
    }
  }
}
.video-gallery-detail.show {
  visibility: visible;
  opacity: 1;
}
.overlay2 {
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  visibility: hidden;
  opacity: 0;
  background-color: rgba(0, 0, 0, 0.7);
  transition: all 0.3s ease;
}
.overlay2.show {
  visibility: visible;
  opacity: 1;
}
.overlay3 {
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  visibility: hidden;
  opacity: 0;
  background-color: rgba(0, 0, 0, 0.7);
  transition: all 0.3s ease;
}
.overlay3.show {
  visibility: visible;
  opacity: 1;
}
