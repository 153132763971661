.section-secondary-banner {
  // margin-top: 98px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 386px;
  @media (max-width: 575px) {
    height: 250px;
  }
  .banner--text-container {
    text-align: center;
    // padding-block: 50px;
    margin-top: 46px;
    .header-box {
      margin-bottom: 13px;
    }
  }
  .sub-head {
    max-width: 500px;
    text-align: center;
  }
}
