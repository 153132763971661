.card {
  border: none;
  background-color: transparent;
  .image-container {
    max-width: 316px;
    overflow: hidden;
    border-radius: 10px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 13px;
    &:hover {
      cursor: pointer;
      img {
        transform: scale(1.1);
      }
    }

    img {
      filter: brightness(1.1);
      aspect-ratio: 280/306;
      transition: transform 0.3s ease-in-out;
      object-fit: cover;
    }
  }
  a:hover + .text-container {
    .sub-title {
      color: $primary-color;
    }
  }
  .text-container {
    max-width: 272px;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    .card--title {
      margin-bottom: 10px;
    }
    .card--price {
      p {
        color: $primary-color;
      }
    }
    .text-dark {
      color: $grey-shade-2 !important;
    }
  }
  .icon-container {
    position: absolute;
    top: 5%;
    left: 80%;
    background-color: white;
    border-radius: 50%;
    padding: 5px;
    stroke: #c9c9c9;
    &:hover {
      cursor: pointer;
    }
  }
}
