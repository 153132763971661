.section-info-banner {
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
  z-index: 4;
  position: relative;
  background-color: $secondary-color;
  .banner-content {
    display: flex;
    gap: 25px;
    // flex-direction: column;
    padding-block: 27px;
    // text-align: center;
    .image-container {
      margin-bottom: 15px;
      height: 30px;
      svg {
        fill: $primary-color;
      }
    }
    .text-container {
      .header-box {
        margin-bottom: 4px;
        p {
          display: inline;
        }
      }
      .para {
        max-width: 365px;
        margin-bottom: 7px;
        margin-left: auto;
        margin-right: auto;
      }
      li {
        list-style: none;
        a {
          color: $tertiary-color;
        }
      }
    }
  }
  .overlay {
    position: fixed;
    display: none;
  }
  .distributor {
    padding: 42px;
    display: flex;
    flex-direction: column;
    gap: 29px;
    width: 382px;
    background-color: #121111;
    border-radius: 20px;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 22;
    transition: opacity 0.3s ease-in-out;
    visibility: hidden;
    opacity: 0;
    .header-box {
      text-align: center;
    }
    .button-container {
      display: flex;
      gap: 15px;
      justify-content: center;
      .image-container {
        width: 135px;
        height: 40px;
        padding: 10px 26px;
        border-radius: 5px;
        background-size: 100% 200%;
        background-image: linear-gradient(
          to bottom,
          #303030 0%,
          #303030 51%,
          #874f47 51%,
          #874f47 101%
        );
        transition: background 0.3s ease-in-out;
        text-align: center;
        &:hover {
          background-position: 0% 100%;
        }
        img {
          width: 50px;
          height: 14px;
        }
      }
    }
    .close-icon {
      position: absolute;
      top: 5px;
      left: 90%;
      cursor: pointer;
      transition: opacity 0.3s ease-in-out;
      svg {
        transition: opacity 0.3s ease-in-out;
      }
    }
  }
}
body.modal-opened {
  .section-info-banner {
    .overlay {
      display: block;
    }
    .distributor-modal {
      visibility: visible;
      opacity: 1;
    }
    // .distributor {
    //   visibility: visible;
    //   opacity: 1;
    // }
  }
}
