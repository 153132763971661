.underline{
    &:after{
        content: '';
        height: 4px;
        width: 39px;
        background-color: $primary-color;
        display: block;
        margin-left: auto;
        margin-right: auto; 
        margin-top: 18px;
    }
}
.underline-grey{
    &::after{
        content: '';
        height: 2px;
        width: 259px;
        background-color: #262626;
        display: block;
        margin-left: 0;
        margin-right: auto;
        margin-top: 21px;
        margin-bottom: 33px;
        opacity: 0.28;
    }
}